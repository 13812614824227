<!--
    - bgText: text - not used
    - backgroundImage: image - not used
-->

<template>
    <div class="cs-embed cs-block" :class="styleClass">
        <div class="cs-block-base">
            <div class="container">
                <div class="header-content">
                    <h2 v-if="data.title" class="cs-title">{{ data.title }}</h2>
                    <h3 v-if="data.subTitle" class="cs-sub-title" v-html="data.subTitle"></h3>
                    <span class="cs-text" v-if="data.text" v-html="data.text"></span>
                </div>
                <span class="embed-component" v-html="data.content"></span>
            </div>
        </div>
    </div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
    name: 'Embed',
    components: {},
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    }, 
    computed: computed('Embed')
}
</script>

<style lang="scss" scoped>
.cs-embed {
    .header-content {
        text-align: center;
        margin-bottom: 10px;
    }
    ::v-deep {
        iframe {
            width: 100% !important;
        }
    }
}
</style>

